import React from 'react';
import { graphql } from 'gatsby';
import SliceZone from '../components/sliceZone';
import SEO from '../components/seo';

export const query = graphql`
	{
		prismic {
			allHomepages {
				...homepageSlices
			}
		}
	}
`;

const IndexPage = (props) => {
	if(!props.data){
		return null;
	}
	return (
		<>
			<SEO title={props.data.prismic.allHomepages.edges[0].node.seo_title} description={props.data.prismic.allHomepages.edges[0].node.seo_description}/>
			<SliceZone body={props.data.prismic.allHomepages.edges[0].node.body} />
		</>
	);
};

export default IndexPage;
